var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container groupMaintain" }, [
    !_vm.isEdit
      ? _c("div", [
          _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("业务编码：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.inputData,
                      callback: function($$v) {
                        _vm.inputData =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "inputData"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.inputData,
                      callback: function($$v) {
                        _vm.inputData =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "inputData"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("产品季：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.inputData,
                      callback: function($$v) {
                        _vm.inputData =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "inputData"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "table_part clearfix" },
            [
              _c("el-tabs", { staticClass: "subnav_wrap" }, [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.fieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.orderList, function(item, index) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "childcontentlist" },
                      [
                        _c("li", { staticClass: "w9p" }, [_vm._v("序号")]),
                        _c("li", { staticClass: "w9p" }, [
                          _vm._v("组合产品系统编码")
                        ]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("业务编码")]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("产品名称")]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("产品品牌")]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("产品季")]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("产品成分")]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("产品分类")]),
                        _c("li", { staticClass: "w9p" }, [_vm._v("产品状态")]),
                        _c(
                          "li",
                          { staticClass: "w10p" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.editHandle(item)
                                  }
                                }
                              },
                              [_vm._v("查看")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ]),
              this.orderList == ""
                ? _c("p", { staticClass: "empty_tip" }, [_vm._v("暂无数据")])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "pagination_wrap fr" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 50],
                      "page-size": _vm.pageSize,
                      layout: "sizes, total, prev, pager, next",
                      total: this.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "form_part clearfix" }, [
          _c("h1", { staticClass: "notice" }, [_vm._v("敬请期待")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }