var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container finishedMaintain" }, [
    !_vm.isEdit
      ? _c("div", [
          _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.productCode,
                      callback: function($$v) {
                        _vm.productCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "productCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialCode,
                      callback: function($$v) {
                        _vm.materialCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.updateUser,
                      callback: function($$v) {
                        _vm.updateUser =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "updateUser"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                  _c("el-date-picker", {
                    attrs: {
                      "unlink-panels": "",
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    on: { change: _vm.dateValChange },
                    model: {
                      value: _vm.dateVal,
                      callback: function($$v) {
                        _vm.dateVal = $$v
                      },
                      expression: "dateVal"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "table_part clearfix" },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticClass: "subnav_wrap",
                  staticStyle: { "min-height": "536px" }
                },
                [
                  _c("singleTable", {
                    attrs: {
                      tableList: _vm.orderList,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.fieldsList
                    },
                    on: { checkHandle: _vm.editHandle }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination_wrap fr" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 50],
                      "page-size": _vm.pageSize,
                      layout: "sizes, total, prev, pager, next",
                      total: this.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function($event) {
                        _vm.currentPage = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "form_part clearfix" }, [
          _c(
            "div",
            { staticClass: "base_wrap clearfix finished" },
            [
              _c("h2", { staticClass: "title" }, [_vm._v("产品基本信息")]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    model: _vm.ruleForm,
                    "label-width": "200px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "产品编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.productCode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "系统编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.insideCode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品条形码" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.productBarcode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品名称" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.productName))])
                  ]),
                  _c("el-form-item", { attrs: { label: "物料编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.materialCode))])
                  ]),
                  _c("el-form-item", { attrs: { label: "供应商名称" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.supplierName))])
                  ]),
                  _c("el-form-item", { attrs: { label: "业务分类" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.businessType,
                            this.busiCategorytypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品分类" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.productClassification,
                            this.ProductClasstypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品系列" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.productSeries
                            ? _vm.ruleForm.productSeries
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品特性" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.productSeason,
                            this.seasontypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品描述" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.ruleForm.specification
                            ? _vm.ruleForm.specification
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "销售季" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.sellSeason,
                            this.SaleSeasoutypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品区分" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.productDistinguish,
                            this.distinguishtypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "销售计量单位" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.measurementUnit,
                            this.SaleUnittypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "颜色" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(_vm.ruleForm.color, this.colortypelist)
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "上市时间" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.listingDate))])
                  ]),
                  _c("el-form-item", { attrs: { label: "长" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.length) + "  米")])
                  ]),
                  _c("el-form-item", { attrs: { label: "生命周期" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.lifeCycle))])
                  ]),
                  _c("el-form-item", { attrs: { label: "宽" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.width) + "  米")])
                  ]),
                  _c("el-form-item", { attrs: { label: "产品状态" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.typeHandle(
                            _vm.ruleForm.productStatus,
                            this.statustypelist
                          )
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "高" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.ruleForm.height) + "  米")])
                  ]),
                  _c("el-form-item", { attrs: { label: "是否活跃" } }, [
                    _vm.ruleForm.active
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.ruleForm.active == true ? "是" : "否")
                          )
                        ])
                      : _c("span")
                  ]),
                  _c("h2", { staticClass: "title" }, [
                    _vm._v("成品产品图文信息")
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "photo", attrs: { label: "缩略图" } },
                    [
                      _c(
                        "ul",
                        _vm._l(this.uploadFileList, function(i) {
                          return _c(
                            "li",
                            { staticClass: "imgclass" },
                            [
                              _c("el-avatar", {
                                attrs: {
                                  shape: "square",
                                  size: 100,
                                  src: i.url
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "last",
                      attrs: { label: "成品产品详细信息" }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.ruleForm.remarks))])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt20 fr" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr10",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }