<template>
  <div class="container finishedMaintain">
    <div v-if="!isEdit">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">产品编号：</span>
            <el-input class="input_single w200 mr10" v-model.trim="productCode" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">物料编号：</span>
            <el-input class="input_single w200 mr10" v-model.trim="materialCode" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">更新人：</span>
            <el-input class="input_single w200 mr10" v-model.trim="updateUser" placeholder="请输入" @keyup.enter.native="searchHandle"></el-input>
          </div>
          <div class="display_ib mr10 mb10">
              <span class="tag">更新日期：</span>
              <el-date-picker
                v-model="dateVal"
                unlink-panels
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="dateValChange"
              ></el-date-picker>
          </div>  
        </div>

        <!-- <el-input class="input_single w200 mr10 mb10" v-model.trim="productCode" placeholder="产品编号"></el-input>
        <el-input class="input_single w200 mr10 mb10" v-model.trim="materialCode" placeholder="物料编号"></el-input> -->
        <div class="actions_btn_wrap down t_right">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          ></el-button>
        </div>
      </div>
      <div class="table_part clearfix">
        <el-tabs class="subnav_wrap" v-loading="tableLoading" style="min-height: 536px;">
          <!-- <ul class="childlist">
            <li :class="item.class" v-for="(item, index) in fieldList" :key="index">{{item.name}}</li>
          </ul>
          <div class="allparentlist">
            <ul
              class="childcontentlist"
              v-for="(item, index) in orderList"
              :key="index">
              <li class="w10p">{{item.id}}</li>
              <li class="w12p" :title="item.insideCode">{{item.insideCode}}</li>
              <li class="w12p" :title="item.productCode">
              	<span class="underline" @click="editHandle(item)">
              	{{item.productCode}}
              	</span>
              </li>
              <li class="w10p" :title="item.productName">{{item.productName}}</li>
              <li class="w12p" :title="item.materialCode">{{item.materialCode}}</li>
              <li class="w10p" :title="productTypeHandle(item.productClassification)">{{productTypeHandle(item.productClassification)}}</li>
              <li class="w10p" :title="productStatusHandle(item.productStatus)">{{productStatusHandle(item.productStatus)}}</li>
              <li class="w10p">
                <el-button type="text" @click="editHandle(item)">查看</el-button>
              </li>
            </ul>
          </div> -->
          <singleTable
          :tableList='orderList' 
          :tableLeaderFieldsList='leaderFieldsList'
          :tableTailFieldsList="tailFieldsList"
          :tableFieldsList="fieldsList"
          @checkHandle='editHandle'
          ></singleTable>
        </el-tabs>        
        <!-- <p v-if="!this.orderList.length&&!tableLoading" class="empty_tip">暂无数据</p> -->
        <div class="pagination_wrap fr">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 50]"
            :page-size="pageSize"
            layout="sizes, total, prev, pager, next"
            :total="this.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑页 -->
    <div class="form_part clearfix" v-else>
      <div class="base_wrap clearfix finished">
        <h2 class="title">产品基本信息</h2>       
        <el-form :inline="true" :model="ruleForm" ref="ruleForm"  label-width="200px" class="demo-form-inline">
          <el-form-item label="产品编号">
            <span>{{ruleForm.productCode}}</span>
          </el-form-item> 
          <el-form-item label="系统编号">
            <span>{{ruleForm.insideCode}}</span>
          </el-form-item> 
          <el-form-item label="产品条形码">
            <span>{{ruleForm.productBarcode}}</span>
          </el-form-item>
          <el-form-item label="产品名称">
            <span>{{ruleForm.productName}}</span>
          </el-form-item>
          <el-form-item label="物料编号">
            <span>{{ruleForm.materialCode}}</span>
          </el-form-item>
          <el-form-item label="供应商名称">
            <span>{{ruleForm.supplierName}}</span>
          </el-form-item>
          <el-form-item label="业务分类">
            <span>{{typeHandle(ruleForm.businessType, this.busiCategorytypelist)}}</span>
          </el-form-item>
          <el-form-item label="产品分类">
						<span>{{typeHandle(ruleForm.productClassification,this.ProductClasstypelist)}}</span>
          </el-form-item>
          <el-form-item label="产品系列">
           <span>{{ruleForm.productSeries ? ruleForm.productSeries : ''}}</span>
          </el-form-item>  
          <el-form-item label="产品特性">
						<span>{{typeHandle(ruleForm.productSeason,this.seasontypelist)}}</span>
          </el-form-item>
          <el-form-item label="产品描述">
            <span>{{ruleForm.specification ? ruleForm.specification : ''}}</span>
          </el-form-item> 
          <el-form-item label="销售季">
						<span>{{typeHandle(ruleForm.sellSeason,this.SaleSeasoutypelist)}}</span>
          </el-form-item>
          <el-form-item label="产品区分">
						<span>{{typeHandle(ruleForm.productDistinguish,this.distinguishtypelist)}}</span>
          </el-form-item>
          <el-form-item label="销售计量单位">
						<span>{{typeHandle(ruleForm.measurementUnit,this.SaleUnittypelist)}}</span>
          </el-form-item>
          <el-form-item label="颜色">
						<span>{{typeHandle(ruleForm.color,this.colortypelist)}}</span>
          </el-form-item>
          <el-form-item label="上市时间">
            <span>{{ruleForm.listingDate}}</span>
          </el-form-item>
          <el-form-item label="长">
            <span>{{ruleForm.length}}&nbsp;&nbsp;米</span>
          </el-form-item>
          <el-form-item label="生命周期">
              <span>{{ruleForm.lifeCycle}}</span>
          </el-form-item>
          <el-form-item label="宽">
             <span>{{ruleForm.width}}&nbsp;&nbsp;米</span>
          </el-form-item>
          <el-form-item label="产品状态" >
						<span>{{typeHandle(ruleForm.productStatus,this.statustypelist)}}</span>
          </el-form-item>
          <el-form-item label="高">
             <span>{{ruleForm.height}}&nbsp;&nbsp;米</span>
          </el-form-item>
          <el-form-item label="是否活跃">
             <span v-if="ruleForm.active">{{ruleForm.active==true?"是":"否"}}</span>
             <span v-else></span>
          </el-form-item>
          <!-- <el-form-item label="备注">
             <span>{{ruleForm.remarks}}</span>
          </el-form-item> -->


          
          <!-- <el-form-item label="计量单位">
						<span>{{typeHandle(ruleForm.measurementUnit,this.SaleUnittypelist)}}</span>
          </el-form-item>
          <el-form-item label="供应商名称" >
             <span>{{ruleForm.supplierName}}</span>
          </el-form-item>

          <el-form-item label="上市日期">
            <span>{{ruleForm.listingDate}}</span>
          </el-form-item>
          <el-form-item label="产品季">
						<span>{{typeHandle(ruleForm.productSeason,this.seasontypelist)}}</span>
          </el-form-item> -->
          
          <h2 class="title">成品产品图文信息</h2>
          
          <el-form-item label="缩略图" class="photo">
          	<ul>
		       		<li class="imgclass" v-for="i in this.uploadFileList">
		       	    <el-avatar shape="square" :size="100" :src="i.url"></el-avatar>
		       		</li>
		       	</ul>
            
          </el-form-item>
          <el-form-item label="成品产品详细信息"  class="last">
          	<span>{{ruleForm.remarks}}</span>
            <!--<el-input
              type="textarea"
              disabled
              :autosize="{ minRows: 8, maxRows: 4}"
              placeholder="多行输入"
              v-model="ruleForm.remarks"
              maxlength="1000"
              show-word-limit>
            </el-input>-->
          </el-form-item>
          
          
        </el-form>

      </div>

      <div class="mt20 fr">
        <el-button type="primary" class="mr10" @click="resetForm('ruleForm')">取消</el-button>
      </div>
    </div>
    
   
  </div>
</template>

<script>
import singleTable from "@/layouts/components/common/SingleTable.vue";
import { 
	purchasegetuuid,
	finishedapiSearch,
  finishedtypeadd,
  finishedtypedel,
  finishedtypeview,
  finishedtypeupd
} from "@/api/module/finishedproductApi.js";
import { 
  deleteUploadFile,
  searchUploadFile
} from "@/api/module/masterDataApi.js";
import{
	materialStatusList,
	fabrictypeProductSeason,
	fabrictypeSaleSeasou,
	fabrictypeProductDistinguish,
	fabrictypeSaleUnit,
	fabrictypeProductClass,
  fabrictypeProductcolor,
  fabrictypedictionaries
} from "@/api/module/dictionariesApi.js";
import { timeFormat,getTimeRange} from '@/utils/index.js';
export default {
  name: "finishedMaintain",
  props: [],
  data() {
    return {
      // 上传字段
      // dialogImageUrl: '',
      // dialogVisible: false,
        updateUser:'',
      dateVal:'',
      startTime:'',
      endTime:'',
      disabled: false,
      fileType: '001',
      uploadFileList: [],
      headers: '',
      uploadUrl: process.env.VUE_APP_INFRASTRUCTURE_URL,
      // 上传字段 end

      materialCode:'',
      productCode:'',
      dialogdelVisible:false,
      isEdit: false,
      loadingcommit:false,
      tableLoading: true,
      delid:'',
      updid:'',
      edittag:'',
      // form
      value1: null,
      ruleForm:{
        	productCode:'',
        	materialCode:'',
        	productName:'',
        	length:'',
        	productClassification:'',
        	width:'',
        	measurementUnit:'',
        	height:'',
        	supplierName:'',
        	appearDate:'',
        	color:'',
        	productDistinguish:'',
        	listingDate:'',
        	lifeCycle:'',
        	productSeason:'',
        	productStatus:'',
        	sellSeason:'',
        	remarks:'',
        	uuid:''
     },
      SaleUnittypelist:[],
			ProductClasstypelist:[],
			statustypelist:[],
			seasontypelist:[],
			SaleSeasoutypelist:[],
      orderList: [],
      colortypelist:[],
      distinguishtypelist:[],
      busiCategorytypelist:[],
      total: 0,
      pageSize: 10, //每页显示条数
      currentPage: 1, //页数
      fieldList: [
        {
          name: '序号',
          class: 'w10p'
        },
        {
          name: '成品产品系统编号',
          class: 'w12p'
        },
        {
          name: '产品编号',
          class: 'w12p'
        },
        {
          name: '产品名称',
          class: 'w10p'
        },
        {
          name: '物料编号',
          class: 'w12p'
        },
        {
          name: '产品分类',
          class: 'w10p'
        },
        {
          name: '产品状态',
          class: 'w10p'
        },
        {
          name: '操作',
          class: 'w10p'
        }
      ],
      leaderFieldsList: [
      ],
      fieldsList:[
        {
          name: '序号',
          field: 'idIndex',
          // width:'80'
        },
        // {
        //   name: '成品产品系统编号',
        //   field: 'insideCode',
        //   //class: 'w50p',
        //   width: '140',
        //   //minWidth: '20%'
        // },
        {
          name:'产品编号',
          field:'productCode',
          //class: 'w50p',
          // width: '150',
          //minWidth: '20%'
        },
        {
          name:'产品名称',
          field:'productName',
          //class: 'w50p',
          // width: '150',
          //minWidth: '20%'
        },
        {
          name:'物料编号',
          field:'materialCode',
          //class: 'w50p',
          // width: '150',
          //minWidth: '20%'
        },
        {
          name:'销售季',
          field:'sellSeason',
          //class: 'w50p',
          // width: '150',
          //minWidth: '20%'
        },
        {
          name:'产品状态',
          field:'productStatus',
          proStatus:'proStatus'
          //class: 'w50p',
          // width: '150',
          //minWidth: '20%'
        },
        {
          name:'更新人',
          field:'updateUser',
        },
        {
          name:'更新时间',
          field:'updateTime',
        }
      ],
      tailFieldsList:[
        {
          name: '操作',
          field: '',
          class: '',
          width: '150',
          minWidth: '10%',
          isAction: true,
          hasCheck:true,
        },
      ]

    };
  },
  components: {
    singleTable
  },
  beforeCreate() {

  },
  created() {

  },
  mounted() {
    this.orderStatus = '1';
    this.getSaleUnit();
    this.getProductClass();
    this.getProductStatus();
    this.getProductSeason();
    this.getSaleSeasou();
    this.getProductcolor();
    this.getProductDistinguish();
    this.getbusiCategory();
    this.fun_date();
    this.getPurchaseOrderList();
  },
  activated() {

  },
  watch: {

  },
  computed: {

  },
  methods: {
     dateValChange(){
      console.log(this.dateVal);
      this.searchHandle();
    },
     //固定展示为三个月前的时间范围
    fun_date(){
      this.dateVal=getTimeRange(-90);
      console.log(this.dateVal)
      this.searchHandle();
    },
  	//获取状态名称
  	typeHandle(status,list) {
  		for(var i=0;i<list.length;i++){
      	let item=list[i];
      	if(item.index==status){
      		return item.name
      	}
      }
  	},
  	//获取产品分类名称
  	productTypeHandle(status) {
		  for(var i=0;i<this.ProductClasstypelist.length;i++){
      	let item=this.ProductClasstypelist[i];
      	if(item.index==status){
      		return item.name
      	}
      }
  	}, 	
  	//获取产品状态名称
  	productStatusHandle(status) {
		  for(var i=0;i<this.statustypelist.length;i++){
      	let item=this.statustypelist[i];
      	if(item.index==status){
      		return item.name
      	}
      }
  	},
    // 上传
    handleUploadRemove(file) {
      // 删除缩略图
      console.log(file);
      if (!file.response) {
        deleteUploadFile(file.id).then(res => {
          if (res.code === '0') {
            this.uploadFileList = this.uploadFileList.filter(item => item.id != file.id);
          }
        });
      } else {
        deleteUploadFile(file.response.data.id).then(res => {
          if (res.code === '0') {
            this.uploadFileList = this.uploadFileList.filter(item => item.id != file.id);
          }
        });
      }
    },

    handleUploadSuccess(response, file, fileList) {
      console.log('上传成功：', response, file, fileList);
    },
    beforeUploadUpload(file) {
      let isType = false;
      let fileName = file.name;
      let regex = /(.jpg|.png|.gif|.jpeg)$/;
      if (regex.test(fileName.toLowerCase())) {
        isType = true;
      } else {
        this.$message.error('上传的图片只能是 JPG/PNG/GIF/JPEG/ 格式!');
      }
      return isType;
    },
    // 上传 end

  	//颜色
  	getProductcolor(){ 		
  		fabrictypeProductcolor().then(response => {
				if(response.code === "0") {					
					this.colortypelist = response.data;
				}
			});
  	},
  		//计量单位
  	getSaleUnit(){
  		fabrictypeSaleUnit().then(response => {
				if(response.code === "0") {
					this.SaleUnittypelist = response.data;	
				}
			});
  	},
  	//产品区分
  	getProductDistinguish(){
  		fabrictypeProductDistinguish().then(response => {
				if(response.code === "0") {					
					this.distinguishtypelist = response.data;
				}
			});
    },
     //业务分类
      getbusiCategory(){
        fabrictypedictionaries('ProductBusinessType').then(response => {
          if(response.code === "0") {					
            this.busiCategorytypelist = response.data;
          }
        });
      },
  	//产品分类
  	getProductClass(){
/*   		fabrictypeProductClass().then(response => {
				if(response.code === "0") {
					this.ProductClasstypelist = response.data;
				}
			}); */
       this.ProductClasstypelist = fabrictypeProductClass().data;
  	},
  	//产品状态
  	getProductStatus(){
  		this.statustypelist = materialStatusList().data;
  	},
  	//产品季
  	getProductSeason(){
  		
  		fabrictypeProductSeason().then(response => {
				if(response.code === "0") {
					
					this.seasontypelist = response.data;
				}
			});
  	},
  	//销售季
  	async getSaleSeasou(){ 		
  		// fabrictypedictionaries('FactorySeason').then(response => {
			// 	if(response.code === "0") {					
			// 		this.SaleSeasoutypelist = response.data;
			// 	}
			// });
      let res = await fabrictypedictionaries('FactorySeason')
      this.SaleSeasoutypelist = res.data;
  	},
  	departChange(val) {
      console.log(val)    
      this.$refs['typerules'].clearValidate()
//    this.$refs[typerules].clearValidate();
   },
    resetForm() {
    	this.isEdit = false;
    	this.uploadFileList=[];
      this.ruleForm={};
    },
    searchHandle() {
      // 搜索
      this.purchaseOrderId = this.orderCode;
      this.getPurchaseOrderList();
    },
    editHandle(index,item) {
      // 编辑项
      this.edittag='updated';
      this.isEdit = true;
      this.updid=item.id;
      finishedtypeview(item.id).then(response => {
					if(response.code === "0") {
						this.ruleForm=response.data;
						if(this.ruleForm.listingDate!=null&&this.ruleForm.listingDate!=''){
							this.ruleForm.listingDate=timeFormat(response.data.listingDate);
							this.ruleForm.listingDate=this.ruleForm.listingDate.substring(0, 10);
						}
						if(this.ruleForm.lifeCycle!=null&&this.ruleForm.listingDate!=''){
							this.ruleForm.lifeCycle=timeFormat(response.data.lifeCycle);
							this.ruleForm.lifeCycle=this.ruleForm.lifeCycle.substring(0, 10);
						}												
					}
      });
      searchUploadFile(item.uuid, this.fileType).then(res => {
        console.log('文件列表：', res);
        if (res.code === '0') {
          this.uploadFileList = res.data;
          this.uploadFileList.forEach(item => {
            item.url = process.env.VUE_APP_SCM_IMG_URL + item.filepath;
          });
        }
      });
    },
    getuuid(){
    	purchasegetuuid().then(response => {
				if(response.code === "0") {
					this.ruleForm.uuid = response.data;
				}
			});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPurchaseOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPurchaseOrderList();
    },
    getPurchaseOrderList() {
    	this.tableLoading = true;
      // 采购请求列表
      let data = {
        productCode:this.productCode,
        materialCode: this.materialCode,
        startTime: this.startTime,
        endTime: this.endTime,
        updateUser:this.updateUser,
        businessType:'Group'
      };
      finishedapiSearch(this.currentPage, this.pageSize, data).then(res => {
        console.log('采购订单列表：', res);
        if (res.code == '0') {
        	this.tableLoading = false;
          this.orderList = res.data.records;
          this.total = res.data.total;
          this.orderList.forEach((item, index) => {
            if (item.updateTime) {
              item.updateTime = timeFormat(item.updateTime);
            } else {
              item.updateTime = "空";
            }
            if(this.currentPage!=1){
            item.idIndex=(this.currentPage-1)*this.pageSize+(index+1)
            }else{
            item.idIndex=index+1;
            }
          });
          
        }
      });
    }

  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
* {
  box-sizing: border-box;
}
li.imgclass{
	width: 148px;
	height: 148px;
	border-radius: 6px;
	border: 1px solid #c0ccda;
	background-color: #fff;
	overflow: hidden;
	display: inline-block;
	box-sizing: border-box;
	margin: 0 8px 8px 0;
}
.ml {
		margin-left: 10px;
	}
.container {
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 6px;
}

.finishedMaintain {
  padding: 10px 15px;
  .actions_part {
    margin: 10px 0;
  }
}
.table_part {
  position: relative;
  ul {
    li {
      outline: none;
    }
    &.childcontentlist {
      height: 60px;
      line-height: 40px;
    }
    &.childlist {
      li {
        cursor: pointer;
      }
    }
  }
  .submit_btn {
    position: absolute;
    top: -60px;
    right: 0;
  }
}
.edit_parent_list {
  ul {
    &.childcontentlist {
      height: 120px;
    }
  }
  .edit_btn_wrap{
    margin-top: 20px;
  }
}

.pagination_wrap {
  margin-top: 20px;
}

.check_popup_part {
  ul {
    &.childcontentlist {
      height: 80px;
    }
  }
}

ul {
  &.childcontentlist {
    li {
      /*white-space: normal;*/
      overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
    }
  }
}

i.el-icon-view {
  font-size: 20px;
  color: #409eff;
  margin-left: 10px;
  cursor: pointer;
}

.form_part {
  .title {
    margin-bottom: 30px;
    padding: 0 20px;
    font-size: 17px;
    color: #606266;
  }
  .demo-ruleForm {
    width: 45%;
  }
}
select.selectstatus {
		width: 200px;
		height: 40px;
		border-radius: 5px;
		border: 1px solid #DCDFE6;
		text-indent: 1em;
		color: #606266;
		line-height: 20px;
		/*cursor: pointer;*/
		font-size: 14px;
	}
	
	select.selectstatus>option {
		/*cursor: pointer;*/
		font-size: 14px;
	}
	
	select.selectstatus {
		background: #FFFFFF;
	}

</style>
<style>
.subnav_wrap .el-tabs__item {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
  vertical-align: baseline !important;
}
.subnav_wrap.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
}
.subnav_wrap .el-tabs__content {
  overflow: visible;
}
.subnav_wrap .checkbox_list .el-checkbox__label {
  width: 100%;
  padding-left: 0;
  color: #606266;
}
.subnav_wrap .checkbox_list .el-checkbox {
  width: 100%;
}
.subnav_wrap .checkbox_list .el-checkbox__input {
  margin-right: 10px;
}
.subnav_wrap ul.el-dropdown-menu li.el-dropdown-menu__item {
  padding: 0 10px;
}

.finishedMaintain .el-form-item__label {
  font-size: 14px;
  color: #606266;
  font-weight: normal;
}
.finishedMaintain .el-form-item__content {
  width: auto;
}
.finishedMaintain .el-input {
  width: auto;
}
.finishedMaintain .whole_long .el-form-item__content {
  width: 65%;
}
.finishedMaintain .whole_long .el-input {
  width: 100%;
}
.finishedMaintain .el-rate {
  height: 40px;
  line-height: 40px;
  vertical-align: top;
}
.finishedMaintain .el-rate__item {
  vertical-align: baseline;
}
/*.finishedMaintain .graphic_wrap .el-form-item {
  width: 100%;
}
.finishedMaintain   .el-form-item__content {
  width: 60% !important;
}*/
.finishedMaintain  .el-form-item__content .el-upload-list__item > div {
  height: 100%;
}
.finishedMaintain  .el-form-item__content .el-upload-list__item .el-upload-list__item-thumbnail {
  width: auto;
  height: 100%;
}
.finishedMaintain .el-upload--picture-card i.el-icon-plus {
  font-size: 30px !important;
}
.finishedMaintain i.el-icon-download {
  font-size: 20px !important;
}
.finishedMaintain .graphic_wrap i.el-icon-delete {
  margin-left: 0;
  color: #fff;
}
.finishedMaintain .el-form-item.last {
    width: 94%!important;
}
.finishedMaintain .el-form-item.photo{
	width: 94%!important;
}
.finishedMaintain .el-form-item.photo .el-form-item__content{
	width:66%;
}
.finishedMaintain .el-form-item.last .el-form-item__content{
	width:66%;
}
</style>